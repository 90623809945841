<template>
  <!-- Coming soon page-->
  <div class="misc-wrapper" :style=" 'background-image: url(' + imgUrl + '); background-size: cover; background-repeat: no-repeat;'">
    <b-link class="brand-logo">
      <vuexy-logo/>
      <b-img class="ml-1" src="@/assets/images/logo/logo-2.png" fluid alt="Logo Text" style="height: 40px"/>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1" style="text-shadow: 2px 2px black;">
          We are launching sometime soon.
        </h2>
        <p class="mb-2" style="text-shadow: 2px 2px black;">
          I'm planing to release something in the near future.
        </p>
        <b-button
            class="login-button"
            variant="primary"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            href="/contact"
        >
          Contact
        </b-button>
      </div>
    </div>
  </div>
<!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BForm, BFormInput, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BLink,
    BForm,
    BButton,
    BFormInput,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/bg-1920.jpg')
    }
  },
  computed: {
    imgUrl() {
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-misc.scss';
</style>
